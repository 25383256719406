/**
 * Copyright Highway9 Networks Inc.
 */
import {
  AccountCircleRounded,
  ExitToAppRounded,
  PeopleAltOutlined,
  RecordVoiceOverRounded as TenantIcon,
  ShoppingCartRounded,
  GroupWorkRounded,
  EqualizerRounded,
  TuneRounded,
  LocationOnRounded,
  DevicesRounded,
  DeviceHub,
  DashboardRounded,
  RouterRounded,
  DeveloperMode,
  SettingsRounded,
  ArrowDownward,
  ArrowUpward,
  AlarmOn,
  AccessTimeOutlined,
  ErrorOutlineRounded,
  VerticalAlignTop,
  ExploreOutlined,
  CodeOutlined,
  Edit,
  InfoTwoTone,
  ReportProblemTwoTone,
  CancelRounded,
  CheckCircle,
  FiberManualRecord,
  AccountTree,
  Security,
  ArrowRight,
  ArrowDropDown,
  AccessTime,
  Mediation,
  AdUnits,
  MoveDown,
  SimCard,
  ContentCopy,
  DataObject,
  ArrowCircleUp,
  ArrowCircleDown,
  HeightRounded,
  MenuBookRounded,
  OpenInBrowserRounded,
  Save,
  DeleteForever,
  AssignmentTurnedIn,
  CellTower,
  AlarmOff,
  Info,
  WifiTethering,
  Topic,
  ImportantDevicesRounded,
  AutoDelete,
  ConnectWithoutContact,
  LeakAdd,
  CrisisAlert,
  TapAndPlay,
  Leaderboard,
  Memory,
  TimerRounded,
  MultipleStop,
  ManageHistoryRounded,
  Visibility,
  VisibilityOff,
  AddCircle,
  CloudUploadRounded,
  Add,
  TroubleshootOutlined,
  SettingsInputAntenna,
  AvTimer,
  RememberMe,
  UpdateOutlined,
  DatasetOutlined,
  Fullscreen,
  Layers,
  LayersClear,
  Label,
  LabelOff,
  Insights,
  Input,
  Description,
  ArrowForward,
  ArrowForwardIos,
  DragIndicator,
  Face2Rounded,
  InfoOutlined,
} from "@mui/icons-material";
import MonitorIcon from '@mui/icons-material/Monitor';
import CellTowerIcon from "@mui/icons-material/CellTower";
import UploadIcon from "@mui/icons-material/Upload";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MenuIcon from "@mui/icons-material/Menu";
import EmailIcon from "@mui/icons-material/Email";
import TimelineIcon from "@mui/icons-material/Timeline";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { grey, red, lightBlue, teal, orange, blue, yellow } from "@mui/material/colors";
import PersonIcon from "@mui/icons-material/Person";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgress, LinearProgress, SvgIconProps } from "@mui/material";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import LockIcon from "@mui/icons-material/Lock";
import DeleteIcon from "@mui/icons-material/Delete";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import PageviewIcon from "@mui/icons-material/Pageview";
import GroupIcon from "@mui/icons-material/Group";
import CloudIcon from "@mui/icons-material/Cloud";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import LanguageIcon from "@mui/icons-material/Language";
import { BaseBadge, StyledBadge } from "./StyledBadge";
import BuildIcon from "@mui/icons-material/Build";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NotesIcon from "@mui/icons-material/Notes";
import EditIcon from "@mui/icons-material/Edit";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import Crop169Icon from "@mui/icons-material/Crop169";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import PasswordIcon from "@mui/icons-material/Password";
import DownloadingIcon from "@mui/icons-material/Downloading";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import ErrorIcon from "@mui/icons-material/Error";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import CloseIcon from "@mui/icons-material/Close";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PlaceIcon from "@mui/icons-material/Place";
import DvrIcon from "@mui/icons-material/Dvr";
import FilterListIcon from "@mui/icons-material/FilterList";
import FiberSmartRecordIcon from "@mui/icons-material/FiberSmartRecord";
import HistoryIcon from "@mui/icons-material/History";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import ExtensionIcon from "@mui/icons-material/Extension";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import DatasetLinkedIcon from "@mui/icons-material/DatasetLinked";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import AdjustIcon from "@mui/icons-material/Adjust";
import RestartAlt from "@mui/icons-material/RestartAlt";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import InstallMobileRoundedIcon from "@mui/icons-material/InstallMobileRounded";
import SystemSecurityUpdateRoundedIcon from "@mui/icons-material/SystemSecurityUpdateRounded";
import PhonelinkSetupRoundedIcon from "@mui/icons-material/PhonelinkSetupRounded";
import SystemSecurityUpdateGoodRoundedIcon from "@mui/icons-material/SystemSecurityUpdateGoodRounded";
import AppBlockingRoundedIcon from "@mui/icons-material/AppBlockingRounded";
import DeviceUnknownRoundedIcon from "@mui/icons-material/DeviceUnknownRounded";
import SystemSecurityUpdateWarningRoundedIcon from "@mui/icons-material/SystemSecurityUpdateWarningRounded";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import TimerIcon from "@mui/icons-material/Timer";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import { OBJ_STATUS } from "../constants";
import { asset } from "../assets";
import TableRowsIcon from "@mui/icons-material/TableRows";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import SensorsIcon from "@mui/icons-material/Sensors";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import SupportIcon from "@mui/icons-material/Support";
import Engineering from "@mui/icons-material/Engineering";
import AddToHomeScreen from "@mui/icons-material/AddToHomeScreen";
import Tune from "@mui/icons-material/Tune";
import SatelliteIcon from '@mui/icons-material/Satellite';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { text } from "./TextResources";
import CodeIcon from '@mui/icons-material/Code';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Search from "@mui/icons-material/Search";
import BroadcastOnPersonalIcon from '@mui/icons-material/BroadcastOnPersonal';
import MapIcon from '@mui/icons-material/Map';
import SecurityIcon from '@mui/icons-material/Security';
import RouterIcon from '@mui/icons-material/Router';
import DevicesIcon from '@mui/icons-material/Devices';
import LaunchIcon from '@mui/icons-material/Launch';
import DescriptionIcon from '@mui/icons-material/Description';
import Radio from "~/types/radio";
import { Hardware } from "~/types/Hardware";
import LensOutlinedIcon from '@mui/icons-material/LensOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import EmergencySvg from "~/assets/emergancySvg";
import IndoorRadioGroup from "~/assets/indoorRadioGroup";
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import OutdoorRadioGroup from "~/assets/outdoorRadioGroup";
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import SimCardOutlinedIcon from '@mui/icons-material/SimCardOutlined';
import DoneIcon from '@mui/icons-material/Done';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import OutputIcon from '@mui/icons-material/Output';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MobilityEdge from "~/assets/MobilityEdge";
import Network from "~/assets/Network";
import NetworkArrowRight from "~/assets/NetworkArrowRight";
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOverflown from "~/assets/CheckCirleOverflown";
import DownloadInstall from "~/assets/DownloadInstall";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

type IconProp = {
  color?:  string;
  size?: number;
  height?: number;
  width?: number;
}

const style = {
  marginBottom: 2,
};

export const Images = {
  H9NLogo: "images/highway9networks.png",
  H9NLogoSmall: "/images/highway9networks_small.svg",
  loginImg: "images/highway9_background.png",
  GCP: "images/Google-cloud-v2.svg.png",
  AWS: "images/AWS_Cloud.svg.png",
  onPrem: "images/OnPrem.svg.jpg",
  radio: asset.celltower,
  IndoorRadio: asset.radioIndoor,
  radioLocation: "images/celltower_red.png",
  GAR: "images/GAR.png",
  GOA: "images/GOA.png",
  GO: "images/GO.png",
  AG: "images/AG.png",
  AO: "images/AO.png",
  GOAR: "images/GOAR.png",
  ARO: "images/ARO.png",
  GOR: "images/GOR.png",
  AR: "images/AR.png",
  RO: "images/RO.png",
  GR: "images/GR.png",
  CERTIFICATE: "images/Certificate_green.png",
  CERTIFICATE_SELF: "images/certificate_self.png",
  QoS: "/images/QoS.png",
  deploymentDiagramOneInterface: "images/DeploymentDiagram1.png",
  deploymentDiagramTwoInterface: "images/DeploymentDiagram2.png",
  bareMetalDeploymentDiagramTwoInterface: "images/BareMetalDeploymentDiagram2.png",
  PhysicalMobilityEdgeAppliance: "images/Physical_Mobility_Edge_Appliance.png",
  PhysicalMobility5GEdgeAppliance: "images/Physical_Mobility_5GEdge_Appliance.png",
  bareMetalInstall5g_1: "images/BareMetal_Installation_1.png",
  bareMetalInstall5g_2: "images/BareMetal_Installation_2.png",
  bareMetalInstallDone: "images/Installation_Complete.png",
  VMwareDeploymentDiagramTwoInterface: "images/VMwareDeploymentDiagram2.png",
  qr: "images/qr.png",
  mobilityDiagram: "images/mobilityProfile-Diagram.svg",
  mobilityRSRPRange: "images/mobilityProfile-RSRPRange.svg",
  wentWrong: "images/wentWrong.png",
  cradlepoint: "images/cradlepoint.png",
  sierraWireless: "images/sierraWireless.png",
  baicells: "images/baicells.png",
  multitech: "images/multitech.png",
  fortinet: "images/fortinet.png",
  digi: "images/digi.png",
  bec: "images/BEC.png",
  connectedDevices: asset.connectedDevices,
  coverageArea: asset.locationMapMarker,
  averageUtilization: asset.networkUtilization,
  loginPageLogo: asset.loginPageLogo,
  highway9Logo: asset.highway9Logo,
  BareMetal4G_EdgeConfiguration_GM: "images/BareMetal4G_EdgeConfiguration_GM.png",
  BareMetal4G_EdgePreRequisitesCheck: "images/BareMetal4G_EdgePreRequisitesCheck.png",
  BareMetal4G_EdgeConfiguration_NM: "images/BareMetal4G_EdgeConfiguration_NM.png",
  BareMetal4G_VMC_Configuration: "images/BareMetal4G_VMC_Configuration.png",
  BareMetal4G_DHCP_Gateway_Edge: "images/BareMetal4G_DHCP_Gateway_Edge.png",
  BareMetal4G_DHCP_IP_Configuration_Gateway: "images/BareMetal4G_DHCP_IP_Configuration_Gateway.png",
  BareMetal4G_DHCP_IP_Configuration_NWExT: "images/BareMetal4G_DHCP_IP_Configuration_NWExt.png",
  BareMetal4G_DHCP_NetworkExtension_Edge: "images/BareMetal4G_DHCP_NetworkExtension_Edge.png",
  BareMetal4G_StaticGatewayEdge: "images/BareMetal4g_StaticGatewayEdge.png",
  BareMetal4g_StaticNetworkExtensionEdg: "images/BareMetal4g_StaticNetworkExtensionEdge.png",
  BareMetal4g_Static_IPConfiguration_NWExt: "images/BareMetal4g_Static_IPConfiguration(NWExt).png",
  BareMetal4g_Static_IP_Configuration_Gateway: "images/BareMetal4g_Static_IP_Configuration_(Gateway).png",
  BareMetal4g_EdgeStatus_Static: "images/BareMetal4g_EdgeStatus_Static.png",
  BareMetal4g_EdgeStatus_DHCP: "images/BareMetal4g_EdgeStatus_DHCP.png",
  deploymentDiagramSingleInterfaceVirtual: "/images/DeploymentDiagramSingleInterfaceVirtual.svg",
  deploymentDiagram5GBareMetal: "/images/DeploymentDiagram5GBareMetal.svg",
  deploymentDiagramPrivateCellularBareMetal: "/images/DeploymentDiagramPrivateCellularBareMetal.svg",
  deploymentDiagramCgwBareMetal: "/images/DeploymentDiagramCgwBareMetal.svg",
  deploymentDiagramCgwVirtual: "/images/DeploymentDiagramCgwVirtual.svg",
};

export const Icons = {
  search: <Search />,
  plus: <Add />,
  forward: <FastForwardIcon />,
  backward: <FastRewindIcon />,
  upload: <UploadIcon titleAccess="upload" />,
  reboot: <RestartAlt titleAccess="reboot" />,
  forceConfig: <SettingsBackupRestoreIcon titleAccess="forceConfig" />,
  mobilityProfile: <WifiTethering titleAccess="mobilityProfile" />,
  syncProfile: <AvTimer titleAccess="syncProfile" />,
  monitoringProfile: <DvrIcon titleAccess="monitoringProfile" />,
  fullscreen: <Fullscreen titleAccess="fullscreen" />,
  floorPlan: <SatelliteIcon titleAccess="floorPlan" />,
  layer: <Layers titleAccess="Layer" />,
  hideLayer: <LayersClear titleAccess="Hide Layer" />,
  label: <Label titleAccess="Toggle Label" />,
  hideLabel: <LabelOff titleAccess="Toggle Label" />,
  import: <Input titleAccess="Import" />,
  play: <PlayArrowIcon titleAccess="play" />,
  stop: <StopIcon titleAccess="stop" />,
  refreshRate: <HistoryIcon titleAccess="refreshRate" />,
  compare: <CompareArrowsIcon titleAccess="Compare" />,
  sources: <FiberSmartRecordIcon />,
  filter: <FilterListIcon />,
  filterAlt: <FilterAltIcon />,
  site: <PlaceIcon fontSize="small" />,
  radioGroup: <WorkspacesIcon fontSize="small" titleAccess="RadioGroup" />,
  radioGroupIndoor: <ApartmentIcon fontSize="small" titleAccess="RadioGroupIndoor" />,
  radioGroupOutdoor: <WifiTethering fontSize="small" sx={{ mb: 0.5 }} titleAccess="RadioGroupOutdoor" />,
  building: <ApartmentIcon fontSize="small" titleAccess="" />,
  cloudDownload: <CloudDownloadIcon titleAccess="cloudDownload" />,
  cloudUpload: <CloudUploadRounded titleAccess="cloudUpload" />,
  sas: <Topic titleAccess="" />,
  download: <FileDownloadIcon titleAccess="download" />,
  downloading: <DownloadingIcon titleAccess="downloading" />,
  downloadReady: <FileDownloadDoneIcon titleAccess="downloadReady" />,
  tenant: <TenantIcon titleAccess="Tenant" />,
  password: <PasswordIcon titleAccess="password" />,
  editIcon: <EditIcon titleAccess="Edit" />,
  notesIcon: <NotesIcon />,
  error: <ErrorIcon titleAccess="Error" />,
  orderManagement: <ShoppingCartRounded />,
  serviceInstance: <GroupWorkRounded />,
  userIcon: <AccountCircleRounded style={{ color: "616161", ...style }} titleAccess="User" />,
  code: <CodeOutlined />,
  logoutIcon: <ExitToAppRounded style={{ width: 20 }} titleAccess="logout" />,
  chevronLeftIcon: <ChevronLeftIcon />,
  chevronRightIcon: <ChevronRightIcon />,
  userManagement: <PeopleAltOutlined />,
  cloudDashboard: <EqualizerRounded />,
  settings: <TuneRounded titleAccess="Settings" />,
  profiles: <DatasetOutlined titleAccess="Profiles" />,
  inboxIcon: <InboxIcon titleAccess="Inbox" />,
  menuIcon: <MenuIcon titleAccess="Menu" />,
  edge: <DeviceHub style={style} titleAccess="Edge" />,
  deviceHub: <DeviceHub color="primary" sx={{width: "30px", height: "33px"}} titleAccess="DeviceHub" />,
  location: <LocationOnRounded style={style} titleAccess="location" />,
  compass: <ExploreOutlined style={style} titleAccess="Compass" />,
  altitude: <VerticalAlignTop style={style} titleAccess="Altitude" />,
  IndoorRadio: (
    <img
      title="Radio: Indoor"
      alt="Radio Indoor"
      src={Images.IndoorRadio}
      style={{ width: 18, height: 18, marginBottom: "-5px" }}
    />
  ),
  MapIcon: (
    <img
      title="Map"
      about="Map"
      src={asset.map}
      style={{ width: 27, height: 33 }}
    />
  ),
  SecurityIcon: (
    <img
      title="Security"
      about="Security"
      src={asset.security}
      style={{ width: 27, height: 33 }}
    />
  ),
  OutdoorRadio: <CellTowerIcon titleAccess="Radio: Outdoor" />,
  radioSimulated: (
    <BaseBadge title="Radio Simulated" badgeContent={"SIM"} color="secondary">
      <CellTowerIcon />
    </BaseBadge>
  ),
  radioBeta: (
    <BaseBadge badgeContent={"Beta!"} color="secondary" title="Radio Beta">
      <CellTowerIcon />
    </BaseBadge>
  ),
  subscribers: <DevicesRounded style={style} titleAccess="Subscribers" />,
  subscriber: <AdUnits style={style} titleAccess="Subscriber" />,
  subscriberBig: <AdUnits style={{ width: 80, height: 80, marginLeft: 30, marginTop: 30 }} titleAccess="" />,
  dashboard: <DashboardRounded />,
  monitor: <MonitorIcon sx={{ fontSize: "1rem", color: "#5B5B5B" }} />,
  RAN: <RouterRounded />,
  APN_DNN: <AccountTree style={style} />,
  policies: <ViewInArIcon titleAccess="Policies" />,
  metrics: <TimelineIcon titleAccess="Metrics" />,
  simulators: <DeveloperMode titleAccess="Simulators" />,
  administration: <SettingsRounded titleAccess="Admin" />,
  tenantAdminRole: <SupervisedUserCircleIcon titleAccess="Tenant Admin Role" />,
  operatorRole: <ManageAccountsIcon titleAccess="Operator Role" />,
  email: <EmailIcon titleAccess="Email" />,
  dataIn: <ArrowDownward />,
  dataOut: <ArrowUpward />,
  alarm: <AlarmOn titleAccess="Alarm On" />,
  alarmOff: <AlarmOff titleAccess="Alarm Off" />,
  copyIcon: <ContentCopy />,
  activeSubscriber: <CheckCircleOutlineIcon style={{ ...style, color: "#026950" }} titleAccess="ActiveSubscriber" />,
  inActiveSubscriber: <BlockIcon style={{ color: grey[500] }} titleAccess="InActiveSubscriber" />,
  simcard: <SimCard style={{ color: "#026950" }} />,
  esimcard: (
    <StyledBadge badgeContent={"e"} color="secondary">
      <SimCard style={{ color: "#026950" }} />
    </StyledBadge>
  ),
  accessTimeIcon: <AccessTimeOutlined style={style} titleAccess="" />,
  badHealth: <ErrorOutlineRounded style={{ color: red[500] }} titleAccess="Bad Health" />,
  disabledIcon: <BlockIcon style={{ ...style, color: grey[500] }} titleAccess="Disabled" />,
  cmpFailedStateIcon: <BlockIcon style={{ ...style, color: red[500] }} titleAccess="Disabled" />,
  enabledIcon: <CheckCircleOutlineIcon style={{ ...style, color: "#07695F" }} />,
  failureIcon: <CancelOutlinedIcon />,
  connected: <CheckCircle style={{ color: "#07695F" }} titleAccess="Connected" />,
  logs: <MenuBookRounded titleAccess="Logs" />,
  logging: <DvrIcon titleAccess="logging" />,
  user: <PersonIcon titleAccess="User" />,
  vertDots: <MoreVertIcon />,
  edit: <Edit style={{ width: 20 }} titleAccess="Edit" />,
  users: <GroupIcon />,
  view: <PageviewIcon />,
  login: <LoginIcon titleAccess="Login" />,
  logout: <LogoutIcon titleAccess="Logout" />,
  delete: <DeleteIcon titleAccess="Delete" />,
  markForDeletion: <AutoDelete />,
  deleting: (
    <span style={{ display: "inline-block" }}>
      <DeleteIcon titleAccess="Deleting" />
      <LinearProgress color="error" style={{ width: 12, marginLeft: 4, marginTop: -7 }} />
    </span>
  ),
  waiting: <TimerRounded style={{ color: grey[500] }} titleAccess="waiting" />,
  operation: <SettingsIcon titleAccess="Operation" />,
  outlinedOperation: <SettingsOutlinedIcon titleAccess="Operation" />,
  SAML: <LockIcon />,
  add: <AddCircle titleAccess="Add" />,
  addAbove: <AddCircle style={{ color: lightBlue[500] }} />,
  addBelow: <AddCircle style={{ color: teal[500] }} />,
  disableAdd: <AddCircle style={{ color: grey[500] }} />,
  info: <InfoTwoTone style={{ ...style, color: lightBlue[800], marginTop: "4px" }} titleAccess="Info" />,
  inProgress: <CircularProgress size={22} thickness={5} style={{ marginBottom: -7 }} />,
  inProgressSmall: <CircularProgress size={16} thickness={3} />,
  warning: <ReportProblemTwoTone style={{ color: "#FFCD38" }} titleAccess="warning" />,
  warningOrangeColor: <ReportProblemTwoTone style={{ color: "#FF8C00" }} />,
  warningRedColor: <ReportProblemTwoTone style={{ color: "red" }} />,
  operator_role: <ManageAccountsIcon titleAccess="operator_role" />,
  tenant_admin_role: <SupervisedUserCircleIcon titleAccess="Tenant_admin_role" />,
  other_roles: <AssignmentIndIcon />,
  cancel: <CancelRounded titleAccess="cancel" style={{ color: red[500] }} />,
  cancelGray: <CancelRounded style={{ color: grey[500] }} />,
  active: <CheckCircle style={{ color: "#026950" }} titleAccess="Active" />,
  network: <CloudIcon titleAccess="Network" />,
  QOSProfile: <img src={Images.QoS} height="32px" alt="QoS" style={{ marginBottom: -10 }} />,
  VMZ: <Security titleAccess="" />,
  application: <ViewInArIcon />,
  SettingsBackupRestoreIcon: <SettingsBackupRestoreIcon />,
  ArrowRightAltIcon: <ArrowRightAltIcon />,
  green: <FiberManualRecord style={{ color: "#07695F" }} />,
  orange: <FiberManualRecord style={{ color: orange[500] }} />,
  grey: <FiberManualRecord style={{ color: grey[500] }} />,
  red: <ErrorIcon style={{ color: red[500] }} />,
  warningCircle: <ErrorIcon style={{ color: yellow[800] }} />,
  redSmall: <FiberManualRecord style={{ color: red[500], width: 12, height: 12, paddingBottom: 2 }} titleAccess="" />,
  orangeSmall: (
    <FiberManualRecord style={{ color: orange[500], width: 12, height: 12, paddingBottom: 2 }} titleAccess="" />
  ),
  blue: <FiberManualRecord style={{ color: blue[500] }} />,
  arrowUp: <ArrowUpward style={{ color: grey[500] }} />,
  arrowDown: <ArrowDownward style={{ color: grey[500] }} />,
  expand: <ArrowRight style={{ color: grey[500] }} />,
  collapse: <ArrowDropDown style={{ color: grey[500] }} titleAccess="Collapse" />,
  time: <AccessTime style={{ color: grey[500] }} titleAccess="Time" />,
  routed: <LanguageIcon titleAccess="" />,
  bridged: <Mediation titleAccess="" />,
  installationSteps: <MoveDown titleAccess="InstallationSteps" />,
  vpnKey: <VpnKeyIcon titleAccess="" />,
  summary: <DataObject titleAccess="Summary" />,
  uplink: <ArrowCircleUp />,
  downlink: <ArrowCircleDown />,
  any: <HeightRounded />,
  defaultView: <DevicesRounded />,
  topNView: <ImportantDevicesRounded color="primary" />,
  buildIcon: <BuildIcon />,
  primary: <CallSplitIcon />,
  secondary: <AltRouteIcon />,
  edgePoolIcon: <ViewStreamIcon titleAccess="Edge Pool" />,
  standaloneIcon: <Crop169Icon titleAccess="Standalone" />,
  upgrade: <OpenInBrowserRounded titleAccess="upgrade" />,
  save: <Save titleAccess="Save" />,
  discard: <DeleteForever titleAccess="Discard" />,
  sign: <AssignmentTurnedIn titleAccess="Sign" />,
  cellTower: <CellTower />,
  cellTowerGreen: <CellTower style={{ color: "#03695F" }} />,
  cellTowerRed: <CellTower style={{ color: red[500] }} />,
  cellTowerOrange: <CellTower style={{ color: orange[500] }} />,
  cellTowerBlue: <CellTower style={{ color: blue[500] }} />,
  cellTowerGrey: <CellTower style={{ color: grey[500] }} />,
  neighbourIcon: <CellTower />,
  systemSettingsIcon: <SettingsSuggestIcon titleAccess="Settings" />,
  unknownIcon: <ExtensionIcon color="disabled" titleAccess="unKnown" />,
  eventsIcons: <MyLocationIcon titleAccess="Location" />,
  vendor: <BroadcastOnPersonalIcon titleAccess="Vendor" />,
  mapIcon: <MapIcon sx={{width: "27px", height: "33px"}} titleAccess="Map" color="primary"  />,
  plusIcon: <Add titleAccess="Plus" color="primary"/>,
  securityIcon : <SecurityIcon sx={{width: "27px", height: "33px"}} titleAccess="Security" color="primary"  />,
  routerIcon: <RouterIcon sx={{width: "27px", height: "33px"}} titleAccess="Router" color="primary" />,
  devicesIcon: <DevicesIcon sx={{width: "30px", height: "33px"}} titleAccess="Devices" color="primary"  />,
  launchIcon: <LaunchIcon titleAccess="Launch" color="primary"  />,
  descriptionIcon: <DescriptionIcon titleAccess="Description" color="primary"  />,
  closeIcon: (props: SvgIconProps) => <CloseIcon titleAccess="Close" {...props} />,
  infoIcon: (props: SvgIconProps) => <Info {...props} />,
  cradlepoint: <img alt="Cradlepoint" src={Images.cradlepoint} style={{ width: 16, height: 16, verticalAlign: "middle" }} />,
  sierraWireless: <img alt="Sierra Wireless" src={Images.sierraWireless} style={{ width: 16, height: 16, verticalAlign: "middle" }} />,
  baicells: <img alt="Baicells" src={Images.baicells} style={{ width: 16, height: 16, verticalAlign: "middle" }} />,
  bec: <img alt="BEC" src={Images.bec} style={{ width: 16, height: 16, verticalAlign: "middle" }} />,
  multitech: <img alt="MultiTech" src={Images.multitech} style={{ width: 16, height: 16, verticalAlign: "middle" }} />,
  fortinet: <img alt="Fortinet" src={Images.fortinet} style={{ width: 16, height: 16, verticalAlign: "middle" }} />,
  digi: <img alt="Digi" src={Images.digi} style={{ width: 16, height: 16, verticalAlign: "middle" }} />,
  settingsInputComponentIcon: <SettingsInputComponentIcon />,
  operatorDetails: <DatasetLinkedIcon />,
  adjustIcon: <AdjustIcon />,
  troubleshoot: <MonitorHeartIcon titleAccess="Troubleshoot" />,
  accessibility: <ConnectWithoutContact titleAccess="User" />,
  retainibility: <AdsClickIcon />,
  mobility: <LeakAdd />,
  integrity: <CrisisAlert />,
  availability: <TapAndPlay titleAccess="Availability" />,
  utilization: <Leaderboard />,
  aaaServer: <RememberMe />,
  hardwareInventory: <Memory titleAccess="HardwareInventory" />,
  flows: <MultipleStop titleAccess="FlowsMon" />,
  ping: <TroubleshootOutlined titleAccess="pingMon" />,
  export: <OpenInNewIcon titleAccess="export" />,
  processing: <ManageHistoryRounded titleAccess="processing" />,
  available: <InstallMobileRoundedIcon titleAccess="available" />,
  downloaded: <SystemSecurityUpdateRoundedIcon titleAccess="downloaded" />,
  installed: <PhonelinkSetupRoundedIcon titleAccess="Installed" />,
  enabled: <SystemSecurityUpdateGoodRoundedIcon style={{ color: "#07695F" }} titleAccess="Enabled" />,
  deleteDeviceInfo: <AppBlockingRoundedIcon style={{ color: red[500] }} />,
  errorDeviceInfo: <DeviceUnknownRoundedIcon style={{ color: red[500] }} />,
  disabledDeviceInfo: <SystemSecurityUpdateWarningRoundedIcon style={{ color: orange[500] }} />,
  visibility: <Visibility titleAccess="visibility" />,
  visibilityOff: <VisibilityOff titleAccess="visibilityOff" />,
  antenna: <SettingsInputAntenna sx={{ fontSize: `20px !important` }} />,
  antenna_30: <SettingsInputAntenna sx={{ fontSize: `30px !important` }} />,
  tableRowsIcon: <TableRowsIcon />,
  graphs: <SsidChartIcon />,
  sensors: <SensorsIcon />,
  misc: <MiscellaneousServicesIcon />,
  syncStatusInitialization: <UpdateOutlined style={{ color: orange[500] }} />,
  syncStatusSynchronized: <AlarmOn style={{ color: "#03695F" }} />,
  syncStatusHoldOver: <AlarmOn style={{ color: orange[500] }} />,
  syncStatusHoldOverExpired: <AlarmOn style={{ color: red[500] }} />,
  syncStatusNA: <AlarmOn style={{ color: red[500] }} />,
  certificate: <WorkspacePremiumIcon />,
  supportChannel: <SupportIcon />,
  system: <Engineering />,
  accessConfig: <AddToHomeScreen />,
  general: <Tune />,
  exportKpi: <Insights />,
  InsertChartIcon: <InsertChartOutlinedIcon />,
  templateGroup: <BubbleChartIcon />,
  templateAreaChart: <BarChartIcon />,
  templateLineChart: <ShowChartIcon />,
  connectedDevices: <img src={Images.connectedDevices} alt={`${text.totalConnectedDevicesTitle} image`} />,
  coverageArea: <img src={Images.coverageArea} alt={`${text.coverageAreaTitle} image`} />,
  averageUtilization: <img src={Images.averageUtilization} alt={`${text.averageUtilizationTitle} image`} />,
  apiDocs: <CodeIcon />,
  license: <Description />,
  arrowForward: <ArrowForward />,
  WhitelistPolicy: <PolicyOutlinedIcon />,
  DragIndicator: <DragIndicator style={{ color: 'black' }} />,
  circle: <LensOutlinedIcon />,
  information: <Info />,
  VirtualEngineer: <Face2Rounded color="primary" />,
  NavigateNextIcon: (props: SvgIconProps) => <NavigateNextIcon {...props} />,
  InfoOutlined: (props: SvgIconProps) => <InfoOutlined {...props} />,
  EmergencyIcon: ({color, size}: IconProp) => <EmergencySvg color={color} size={size} />,
  AddCircle: (props: SvgIconProps) => <AddCircle {...props} />,
  IndoorRadioGroup: ({size}: IconProp) => <IndoorRadioGroup size={size} />,
  OutdoorRadioGroup: ({size}: IconProp) => <OutdoorRadioGroup size={size} />,
  DeleteIcon: (props: SvgIconProps) => <DeleteIcon {...props} />,
  AccountTree: (props: SvgIconProps) => <AccountTree {...props} />,
  CheckCircleOutlineIcon: (props: SvgIconProps) => <CheckCircleOutlineIcon {...props} />,
  CancelRounded: (props: SvgIconProps) => <CancelRounded {...props} />,
  HeightRounded: (props: SvgIconProps) => <HeightRounded {...props} />,
  ArrowCircleUp: (props: SvgIconProps) => <ArrowCircleUp {...props} />,
  ArrowCircleDown: (props: SvgIconProps) => <ArrowCircleDown {...props} />,
  ViewInArIcon: (props: SvgIconProps) => <ViewInArIcon {...props} />,
  DevicesRounded: (props: SvgIconProps) => <DevicesRounded {...props} />,
  Add: (props: SvgIconProps) => <Add {...props} />,
  QoSIcon: (props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => <img src={Images.QoS} alt="QoS" {...props} />,
  DevicesRoundedIcon: (props: SvgIconProps) => <DevicesRoundedIcon {...props} />,
  PhoneAndroidOutlinedIcon: (props: SvgIconProps) => <PhoneAndroidOutlinedIcon {...props} />,
  SimCardOutlinedIcon: (props: SvgIconProps) => <SimCardOutlinedIcon {...props} />,
  DoneIcon: (props: SvgIconProps) => <DoneIcon {...props} />,
  CancelOutlinedIcon: (props: SvgIconProps) => <CancelOutlinedIcon {...props} />,
  UpdateOutlinedIcon: (props: SvgIconProps) => <UpdateOutlinedIcon {...props} />,
  OutputIcon: (props: SvgIconProps) => <OutputIcon {...props} />,
  OpenInNewIcon: (props: SvgIconProps) => <OpenInNewIcon {...props} />,
  KeyboardArrowDownIcon: (props: SvgIconProps) => <KeyboardArrowDownIcon {...props} />,
  KeyboardArrowUpIcon: (props: SvgIconProps) => <KeyboardArrowUpIcon {...props} />,
  MobilityEdge: ({size}: IconProp) => <MobilityEdge size={size} />,
  Network: ({size}: IconProp) => <Network size={size} />,
  NetworkArrowRight: (props: IconProp) => <NetworkArrowRight {...props} />,
  DeviceHubIcon: (props: SvgIconProps) => <DeviceHubIcon {...props} />,
  CheckCircleIcon: (props: SvgIconProps) => <CheckCircleIcon {...props} />,
  ArrowForwardIos: (props: SvgIconProps) => <ArrowForwardIos {...props} />,
  CheckCircleOverflown: ({size}: IconProp) => <CheckCircleOverflown size={size} />,
  DownloadInstall: ({size}: IconProp) => <DownloadInstall size={size} />,
  AddCircleOutlineIcon: (props: SvgIconProps) => <AddCircleOutlineIcon {...props} />
};

export const withLoading = (Icon: JSX.Element) => {
  return (
    <div style={{ position: "relative", display: "inline-block", marginTop: "5px" }}>
      {Icon}
      <LinearProgress style={{ position: "absolute", bottom: "-5px", left: "0", width: "18px" }} />
    </div>
  );
};

export const withWaiting = (Icon: JSX.Element, color = "#fc8d26") => {
  return (
    <div style={{ position: "relative", display: "inline-block", marginTop: "5px" }}>
      {Icon}

      <TimerIcon
        style={{ position: "absolute", top: "-5px", right: "-5px", fontSize: "small", color: color }}
        titleAccess="Time"
      />
    </div>
  );
};

export const withError = (Icon: JSX.Element, color = red[500]) => {
  return (
    <div style={{ position: "relative", display: "inline-block", marginTop: "5px" }}>
      {Icon}
      <ErrorIcon
        style={{ position: "absolute", top: "-5px", right: "-5px", fontSize: "small", color: color }}
        titleAccess="Error"
      />
    </div>
  );
};

export const withDelete = (Icon: JSX.Element, color = red[500] as string) => {
  return (
    <div style={{ position: "relative", display: "inline-block", marginTop: "5px" }}>
      {Icon}
      <AutoDelete
        style={{ position: "absolute", top: "-5px", right: "-5px", fontSize: "small", color: color }}
        titleAccess="AutoDelete"
      />
    </div>
  );
};

/**
 *
 * @param {JSX.Element} Icon
 * @param {string } state  DEPLOYED | DEPLOYING | NOT_DEPLOYED | FAILED | MARKED_FOR_REBOOT | MARKED_FOR_DELETE | DELETING | MARKED_FOR_UPDATE | WAITING | UPDATING
 * @returns {JSX.Element}
 * @example
 *
 * const Icon = withState(<DeviceIcontitleAccess=""/>, DEPLOYED)
 */
export const withState = (Icon: JSX.Element, state: OBJ_STATUS) => {
  switch (state) {
    case OBJ_STATUS.DEPLOYING:
    case OBJ_STATUS.MARKED_FOR_REBOOT:
    case OBJ_STATUS.AUTO_CONFIGURING:
    case OBJ_STATUS.MARKED_FOR_AUTO_CONFIG:
    case OBJ_STATUS.CONFIGURING:
      return withLoading(Icon);
    case OBJ_STATUS.FAILED:
      return withError(Icon);

    case OBJ_STATUS.MARKED_FOR_DELETE:
      return withDelete(Icon, "gray");
    case OBJ_STATUS.DELETING:
      return withDelete(Icon);

    case OBJ_STATUS.NOT_DEPLOYED:
      return withWaiting(Icon, "gray");

    case OBJ_STATUS.MARKED_FOR_UPDATE:
    case OBJ_STATUS.WAITING:
    case OBJ_STATUS.UPDATING:
    case OBJ_STATUS.AUTO_REGISTERING:
    case OBJ_STATUS.MARKED_FOR_DISABLE:
      return withWaiting(Icon);

    case OBJ_STATUS.DEPLOYED:
    default:
      return Icon;
  }
};


function isRadio(data: Radio | Hardware | undefined): data is Radio {
  return (data as Radio).type === "radio";
}

export function getRadioIcon(data?: Radio | Hardware) {
  if (!data) return Icons.OutdoorRadio;
  if (isRadio(data)) {
    // Radio
    if (data.isSimulatedRadio) {
      return Icons.radioSimulated;
    }
    if (
      data.model === "Indoor" ||
      data.sasConfig?.cbsdCategory === "A" ||
      data.radioGroup?.type === "INDOOR" ||
      data.sasConfig?.cbsdCategory === undefined
    ) {
      return Icons.IndoorRadio;
    }
    if (data.model === "Outdoor" || data.model === "Flex" || data.sasConfig?.cbsdCategory === "B") {
      return Icons.OutdoorRadio;
    }
    return Icons.OutdoorRadio;
  } else {
    // Hardware
    if (data.modelType === "Indoor" || data.modelType === undefined) {
      return Icons.IndoorRadio;
    }
    return Icons.OutdoorRadio;
  }
}
